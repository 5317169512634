import React, { useState, useEffect } from "react";
import "./nav.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBBtn,
  MDBIcon,
  MDBNavbarNav,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";
import { Link } from "react-scroll";
import Corperate from "../component/Coroperate";
import Individual from "../component/Individual";
import Forgetpassword from "../component/Forgetpassword";
import Resetpassword from "../component/Resetpassword";
import LoadingSpinner from "./LoadingSpinner";



export default function App(props) {
  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  const [fullscreenXlModal, setFullscreenXlModal] = useState(
    props.isAuthenticated
      ? false
      : (props.searchParams.get("email") && props.searchParams.get("token")) ||
        props.searchParams.get("rlink")
      ? true
      : false
  );

  const toggleShow = () => {
    setFormtype("login");
    setValues({
    email: "",
    password: "",
  });
    props.isloggedin ? signOut() : setFullscreenXlModal(!fullscreenXlModal);
  };

  toast.configure();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();
  // setting  redirect links 
  const [formtype, setFormtype] = useState(
    props.searchParams.get("email") && props.searchParams.get("token")
      ? "Resetpassword"
      : props.searchParams.get("rlink")
      ? "individual"
      : "login"
  );

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [inderrors, setinderrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const errormessage = <p className="logerror">Invalid Credentials</p>;


  // validation for login 
  const validateind = () => {
    let temp = [];
    if (!values.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      temp.email = "Email address is invalid";
    }
    temp.password = values.password ? "" : "This field is required";
    setinderrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };


  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const login = async (event) => {
    event.preventDefault();
    if(loading){
      return
    }
    if (validateind()) {
      setLoading(true);
      let res = await fetch(props.url + "/v1/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
        credentials: "include",
      });

      let data = await res.json();
      if (await data.success) {
        
        localStorage.setItem("success", true);
        if (data.user[0] === "content_manager") {
          setFullscreenXlModal(false);
          setLoading(false);
          toast.error("You are now logged in");
          props.setisAuthenticated(true);
          props.setIsloggedin(true);
          return;
        } else if (data.user[0] === "admin") {
          window.location.replace(props.admin);
          return;
        } else if (
          data.user[0] === "individual" ||
          data.user[0] === "corporation"
        ) {
          window.location.replace(props.user);
          return;
        } else {
          setLoading(false);
          toast.error("You are not authorized");
        }
        return;
      }
      setLoading(false);
      toast.error(
        (data.message.email ? data.message.email : null) ||
          (data.message != "incorrect" ? data.message : null)
      );
      if (data.message === "incorrect") {
        setError(errormessage);
      }
      return;
    }
  };

  // login ends here

  //  logout start here
  const signOut = async () => {
    let response = await fetch(props.url + "/v1/logout", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    });
    let data = await response.json();
    if (data.success) {
      props.setisAuthenticated(false);
      props.setIsloggedin(false);
    }
    // props.history.push('/auth/login');
  };

const bigscreen = (<MDBNavbar sticky expand="lg" light bgColor="white">
<MDBContainer fluid>
  <MDBNavbarBrand href="#">
    <img
      src="/assets/images/fatt_fatt_normal.png"
      height="30"
      alt=""
      loading="lazy"
    />
  </MDBNavbarBrand>
  <MDBCollapse navbar show={showNavNoTogglerSecond}>
    <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 navnodisplay">
      <MDBNavbarItem>
        <MDBNavbarLink>
          <Link
            className="link"
            to="about"
            spy={true}
            smooth={true}
            offset={20}
            duration={500}
          >
            About
          </Link>
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBNavbarLink>
          <Link
            className="link"
            to="Services"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
          >
            Services
          </Link>
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBNavbarLink>
          <Link
            className="link"
            to="gallery"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
          >
            Gallery
          </Link>
        </MDBNavbarLink>
      </MDBNavbarItem>
      <MDBNavbarItem>
        <MDBNavbarLink href="#">
          <Link
            className="link"
            to="contact"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Contact
          </Link>
        </MDBNavbarLink>
      </MDBNavbarItem>
    </MDBNavbarNav>
  </MDBCollapse>
  <MDBBtn className="mbtn" color="dark" onClick={toggleShow}>
      {props.isloggedin ? "logout" : "Login"}
    </MDBBtn>
</MDBContainer>
</MDBNavbar>);


// const smallscreen = ();


  // logout ends here
  useEffect(() => {}, []);

  return (
    <>
      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalBody>           
              <MDBIcon 
              onClick={toggleShow}
              fas icon="long-arrow-alt-left" 
              size='lg' />
              <div className="forms">
                {formtype === "login" && (
                  <div className="form-content">
                    <div className="headers">
                      <div className="headers-content col-12">
                        {" "}
                        <img src="/assets/images/fatt_fatt_normal.png" height="50" alt="" loading="lazy"
                        />
                      </div>
                      <div className="headers-content col-12 pt-2 ">
                        <p>
                          {" "}
                          {error
                            ? error
                            : "Enter your email and password to Login"}
                        </p>
                      </div>
                    </div>
                    <form onSubmit={login} className="logform">
                      <div className="field-column">
                        <div className="field input-field">
                          <input
                            id="email"
                            style={{ width: "100%" }}
                            type="email"
                            placeholder="Email"
                            className="input"
                            value={values.email}
                            autoFocus
                            error={error}
                            onChange={handleInputChange}
                          />
                        <p id="errors">{inderrors.email}</p>

                        </div>

                        <div className="field input-field">
                        
                          <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            className="password "
                            onChange={handleInputChange}
                            value={values.password}
                          />
                          {showPassword ?
                          <i onClick={()=>setShowPassword(false)} class="fas fa-eye-slash trailing picon" /> :
                          <i onClick={()=>setShowPassword(true)} class="fas fa-eye trailing picon" /> }
                        <p id="errors">{inderrors.password}</p>

                          <i className="bx bx-hide eye-icon"></i>
                        </div>
                        <p>{error.password}</p>

                        <div className="field button-field">
                          <div className="forget">
                            {" "}
                            <span onClick={() => setFormtype("Forgetpassword")}>
                              Forgot password?
                            </span>
                          </div>
                          <button className="navbtn" onClick={login}> {loading ? <LoadingSpinner /> : 'Login'}</button>
                          <div className="formstype-container ">
                            Don't have an account? Sign up as &nbsp;
                            <span
                              className="formstype"
                              onClick={() => setFormtype("corporate")}
                            >
                              Corporate User <span className="formor">or</span>
                            </span>
                            &nbsp;
                            <span
                              className="formstype"
                              onClick={() => setFormtype("individual")}
                            >
                              Individual User &nbsp;
                            </span>
                            
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {formtype === "corporate" && (
                  <Corperate
                    toggleShow={toggleShow}
                    url={props.url}
                    user={props.user}
                    admin={props.admin}
                    setFormtype={setFormtype}
                  />
                )}

                {formtype === "individual" && (
                  <Individual
                    url={props.url}
                    user={props.user}
                    admin={props.admin}
                    setFormtype={setFormtype}
                    searchParams={props.searchParams}
                  />
                )}
           
                {formtype === "Forgetpassword" && (
                  <Forgetpassword
                    url={props.url}
                    user={props.user}
                    admin={props.admin}
                    setFormtype={setFormtype}
                  />
                )}

                {formtype === "Resetpassword" && (
                  <Resetpassword
                    url={props.url}
                    user={props.user}
                    admin={props.admin}
                    setFormtype={setFormtype}
                    searchParams={props.searchParams}
                  />
                )}
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* Nav Starts here  */}
      {bigscreen}
    </>
  );
}
