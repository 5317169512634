import React, { useState } from "react";
import "./Trackform.css";
import {
  MDBRow,
  MDBInputGroup,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";


export default function Trackform(props) {
  const [delivery, setDelivery] = useState({});
  const [orderid, setOrderid] = useState("");

  //for my modal
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const toggleclose = () => setFullscreenXlModal(false);

  function getOrderDetails() {
    fetch(props.url + `/get-landing-delivery-details/${orderid}`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDelivery(data.data);
        setFullscreenXlModal(true);
      });
  }

  const handleChange = (event) => {
    setOrderid(event.target.value);
  };

  return (
    <>
      <MDBRow className=" d-flex justify-content-center " id="track">
        <MDBCol sm="4" md="8" lg="8" xl="8">
          <div className="trackbody">
            <div className="trackcol">
              <MDBInputGroup className="" size="md">
                <input
                  onChange={handleChange}
                  value={orderid}
                  className="form-control"
                  type="text"
                  placeholder="Place your order Id"
                />
                <MDBBtn onClick={getOrderDetails} className="mx-1" color="dark">
                  Track
                </MDBBtn>
              </MDBInputGroup>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      {/* modal for  */}

      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delivery Info</MDBModalTitle>
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleclose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="d-flex align-items-start bg-light mb-3">
                <MDBCol>
                  <div className="timeline-container">
                    <div className="timeline-item">
                      <div className="timeline-item-content">
                        <h5>Cost Of Delivery: {"  "}</h5>
                        <span className="tag"></span>
                        <br />
                        <b>
                         D {delivery && delivery.price ? delivery.price : "Free"}{" "}
                        </b>
                        <span className="circle" />
                      </div>
                    </div>

                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <h5>Service Type :</h5>
                         <span className="tag"></span>
                        <img
                          className="delimg"
                          src={
                            delivery && delivery.service
                              ? props.imgurl + delivery.service.img
                              : ""
                          }
                        />
                        <p>
                          {" "}
                          {delivery && delivery.service
                            ? delivery.service.service_name
                            : ""}
                        </p>
                        <span className="circle" />
                      </div>
                    </div>

                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <h5> Type of Delivery : </h5>
                        <span className="tag"></span> 
                        <p>
                          {" "}
                          <img
                            className="delimg"
                            src={
                              delivery && delivery.boundary
                                ? props.imgurl + delivery.boundary.img
                                : ""
                            }
                          />{" "}
                        </p>
                        <b>
                          <p>
                            {delivery && delivery.boundary
                              ? delivery.boundary.boundary_name
                              : ""}
                          </p>
                        </b>
                        <span className="circle" />
                      </div>
                    </div>

                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <p><h5>From</h5></p>
                        <span className="tag"></span>
                        <p>
                          <i class="fas fa-map-marked"></i> :{" "}
                          {delivery && delivery.from_location
                            ? delivery.from_location.location_name
                            : ""}
                        </p>
                        <p>
                          <i class="fas fa-map-marker"></i> :{" "}
                          {delivery && delivery.fromAddress
                            ? delivery.fromAddress
                            : ""}
                        </p>
                        <span className="circle" />
                      </div>
                    </div>

                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <p><h5>To</h5> </p>
                        <span className="tag"></span> 
                        <p>
                        <i class="fas fa-map-marked"></i>:{" "}
                        {delivery && delivery.to_location
                          ? delivery.to_location.location_name
                          : ""}
                      </p>
                      <p>
                        <i class="fas fa-map-marker"></i> :{" "}
                        {delivery && delivery.toAddress
                          ? delivery.toAddress
                          : ""}
                      </p>
                        <span className="circle" />
                      </div>
                    </div>

                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <h5> Payment Status:{"  "}</h5>
                        <span className="tag"></span> 
                        <p>
                      <b>
                        {delivery && delivery.paid == 0
                          ? "Cash on Pickup"
                          : delivery && delivery.paid == 1
                          ? "Delivery Paid"
                          : ""}
                      </b>
                    </p>
                        <span className="circle" />
                      </div>
                    </div>

                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <h5>Sender: {"  "}</h5>
                        <span className="tag"></span> 
                        <p>
                        Name :{" "}
                        {delivery && delivery.creator
                          ? delivery.creator.first_name +
                            " " +
                            (delivery.creator.last_name
                              ? delivery.creator.last_name
                              : "")
                          : delivery.sender_name}
                      </p>
                      <p>
                        Phone :{" "}
                        {delivery && delivery.creator
                          ? delivery.creator.phone_number
                          : delivery.sender_phone}
                      </p>
                        <span className="circle" />
                      </div>
                    </div>
                    
                    
                    <div className="timeline-item">
                      <div className="timeline-item-content">
                      <h5> Recipient</h5>{" "}
                        <span className="tag"></span> 
                        <p>
                        Name :{" "}
                        {delivery && delivery.reciepient
                          ? delivery.reciepient.first_name +
                            " " +
                            delivery.reciepient.last_name
                          : ""}
                      </p>
                      <p>
                        Phone :{" "}
                        {delivery && delivery.reciepient
                          ? delivery.reciepient.phone
                          : ""}
                      </p>
                        <span className="circle" />
                      </div>
                    </div>
                  </div>
                
                </MDBCol>
              
               
              </div>

              {/* <Timeline></Timeline> */}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="button" color="dark" onClick={toggleclose}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
