import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import * as location from "../79794-world-locations.json";


const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};



export default function PreLoader() {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(undefined);


  return (
            <Lottie options={defaultOptions1} height={200} width={200} />
  );
}
