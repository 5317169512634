import React, { useEffect, useState } from "react";
import "./Service.css";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBFile,
  MDBTabsContent,
  MDBInputGroup,
} from "mdb-react-ui-kit";

export default function Services(props) {
  const [aboutsec, setAboutsec] = useState([]);
  const [preview, setPreview] = useState("");
  const [icons] = useState(["motorcycle", "car-side", "truck"]);

  function fetchData() {
    fetch(props.url + "/aboutsec")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("About from server: ", data);
        setAboutsec([...data]);
        props.setLoadingServices(false);
      });
  }

  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const [modalObj, setModalObj] = useState({});
  const toggleShow = function (id) {
    if (typeof id != "object" && id != undefined) {
      let obj = aboutsec.find((e) => e.id == id);
      setModalObj(obj);
      setPreview(props.imgurl + obj.image);
      console.log("clicked for modal: ", obj);
    }
    setFullscreenXlModal(true);
  };

  const toggleClose = function () {
    setModalObj({});
    setPreview("");
    setFullscreenXlModal(false);
  };

  const updateData = async (event) => {
    //async to update data
    event.preventDefault();
    let response = await fetch(props.url + "/update-aboutsec", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(modalObj),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setAboutsec(
        aboutsec.map((elem) => (elem.id == modalObj.id ? res.data : elem))
      );
      toggleClose();
    }
  };

  const onImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      ///validation no file selected
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      ///validation invalid file
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setModalObj({ ...modalObj, image: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };

  const handleChange = (event) => {
    setModalObj({ ...modalObj, [event.target.id]: event.target.value });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const aboutconent = (
    <MDBRow className="cardrow row-cols-1 row-cols-md-3  ">
      {aboutsec.map((aboutsec, ind) => (
        <MDBCol
          className="service-main"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          key={aboutsec.id}
        >
          <MDBCard className="h-100 single-services">
            <div className="services-thumb">
              <MDBCardImage
                className="img"
                src={props.imgurl + aboutsec.image}
                alt="img"
                position="top"
              />
            </div>
            <MDBCardBody className="services-content">
              <div className="services-icon">
                <MDBIcon className="ms-1" icon={icons[ind]} size="m" />
              </div>
              <MDBCardTitle>{aboutsec.title}</MDBCardTitle>
              <MDBCardText>{aboutsec.content}</MDBCardText>
            </MDBCardBody>
            {props.isAuthenticated && (
              <MDBCardFooter>
                <MDBIcon
                  className="float-right"
                  onClick={() => toggleShow(aboutsec.id)}
                  icon="edit"
                  size="lg"
                />{" "}
              </MDBCardFooter>
            )}
          </MDBCard>
        </MDBCol>
      ))}
    </MDBRow>
  );
  return (
    <>
      {!props.loadingAbout &&
        !props.loadingGallery &&
        !props.loadingServices &&
        !props.timing && (
          <div className="service-container">
            <MDBRow className="row justify-content-center" id="Services">
              <section className="services-area">
                <div className="container">
                  <div className="services-wrapper ">{aboutconent}</div>
                </div>
              </section>
            </MDBRow>

            <MDBModal
              tabIndex="-1"
              show={fullscreenXlModal}
              setShow={setFullscreenXlModal}
            >
              <MDBModalDialog size="fullscreen">
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Content Update</MDBModalTitle>
                    <MDBBtn
                      type="button"
                      className="btn-close"
                      color="none"
                      onClick={toggleClose}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <MDBTabsContent>
                      <MDBRow>
                        {/* Modal for form to update content  */}
                        <MDBCol size="8" sm="6" className="col-example">
                          <MDBInput
                            label=" Enter Title here"
                            id="title"
                            type="text"
                            value={modalObj.title}
                            onChange={handleChange}
                          />
                          <br></br>
                          <MDBInput
                            label="Enter Content here"
                            id="content"
                            textarea
                            rows={4}
                            value={modalObj.content}
                            onChange={handleChange}
                          />
                          <MDBFile
                            onChange={onImageChange}
                            label="Click the icon Below to upload your image"
                            id="customFile"
                          />
                          <br></br>
                          <MDBBtn
                            type="button"
                            color="primary"
                            onClick={updateData}
                          >
                            Update
                          </MDBBtn>
                        </MDBCol>
                        {/* Modal for preveiwing content about to be updated */}
                        <MDBCol
                          className="float-right"
                          size="8"
                          sm="3"
                          key={aboutsec.id}
                        >
                          <MDBCard className="h-100 single-services">
                            <div className="services-thumb">
                              <MDBCardImage
                                className="img"
                                src={preview}
                                alt="img"
                                position="top"
                              />
                            </div>
                            <MDBCardBody className="services-content">
                              <div className="services-icon">
                                <MDBIcon
                                  className="ms-1"
                                  icon="motorcycle"
                                  size="m"
                                />
                              </div>
                              <MDBCardTitle>{modalObj.title}</MDBCardTitle>
                              <MDBCardText>{modalObj.content}</MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                    </MDBTabsContent>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      type="button"
                      color="dark"
                      onClick={toggleClose}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
          </div>
        )}
    </>
  );
}
