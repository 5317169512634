import React, { useState } from "react";
import { MDBFile } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import LoadingSpinner from "../shared/LoadingSpinner";



export default function Corporate(props) {
  toast.configure();
  const [loading, setLoading] = useState();
  const [inderrors, setinderrors] = useState([]);
  const [userDataind, setUserDataind] = useState({
    name: "",
    email: "",
    phonenumber: "",
    address: "",
    password: "",
    avatar: "",
    confirm: "",
  });

  const handleindChange = (event) => {
    setUserDataind({ ...userDataind, [event.target.id]: event.target.value });
  };

  // coroperate form validation
  const validateind = () => {
    let temp = [];
    temp.name = userDataind.name ? "" : "This field is required";
    temp.address = userDataind.address ? "" : "This field is required";
    if (!userDataind.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userDataind.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userDataind.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userDataind.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    temp.password = userDataind.password ? "" : "This field is required";
    if (!userDataind.confirm) {
      temp.confirm = "This field is required";
    } else if (userDataind.confirm !== userDataind.password) {
      temp.confirm = "Passwords dont match";
    }
    setinderrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  // for coroperate form
  const onImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUserDataind({ ...userDataind, avatar: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setUserDataind({ ...userDataind, avatar: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
  };
  const register = async (event) => {
    event.preventDefault();
    if(loading){
      return
    }
    if (validateind()) {
      setLoading(true);
      let response = await fetch(props.url + "/v1/register-company", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userDataind),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          toast.success("Account Created Successfully !! Pending Approval !!");
          props.toggleShow();
        } else {
          if (res.message.email[0]) {
            setLoading(false);
            setinderrors({ ...inderrors, email: res.message.email[0] });
          } else {
            toast.error(res.message);
          }
        }
      }
    }
  };

  return (
    <div className="form-content">
      <div className="headers">
        {" "}
        <img
          src="/assets/images/fatt_fatt_normal.png"
          height="50"
          alt=""
          loading="lazy"
        />
      </div>
      <form className="logform">
        <div className="field-column">
          <div className="field input-field">
            <input
              id="name"
              style={{ width: "100%" }}
              type="text"
              placeholder="Company Name"
              className="input"
              value={userDataind.name}
              autoFocus
              onChange={handleindChange}
            />
            <p id="errors">{inderrors.name}</p>
          </div>

          <div className="field input-field">
            <input
              id="address"
              style={{ width: "100%" }}
              type="text"
              placeholder="Address"
              className="input"
              value={userDataind.lastName}
              autoFocus
              onChange={handleindChange}
            />
            <p id="errors">{inderrors.address}</p>
          </div>

          <div className="field input-field">
            <input
              id="phonenumber"
              style={{ width: "100%" }}
              type="text"
              placeholder="Phone Number"
              className="input"
              value={userDataind.phonenumber}
              autoFocus
              onChange={handleindChange}
            />
            <p id="errors">{inderrors.phonenumber}</p>
          </div>

          

          <div className="field input-field">
            <input
              id="email"
              style={{ width: "100%" }}
              type="email"
              placeholder="Email"
              className="input"
              value={userDataind.email}
              autoFocus
              // error={error}
              onChange={handleindChange}
            />
            <p id="errors">{inderrors.email}</p>
          </div>

          <div className="field input-field d-flex mb-5 ">
            <div className="col-6 field-inside">
              {" "}
              <input
                id="password"
                type="password"
                placeholder="Password"
                className="password"
                onChange={handleindChange}
                value={userDataind.password}
              />
            <p id="errors">{inderrors.password}</p>

              <i className="bx bx-hide eye-icon"></i>{" "}
            </div>

            <div className="col-6 field-inside">
              <input
                id="confirm"
                type="password"
                placeholder="Confirm Password "
                className="password"
                onChange={handleindChange}
                value={userDataind.confirm}
              />
            <p id="errors">{inderrors.confirm}</p>

              <i className="bx bx-hide eye-icon"></i>{" "}
            </div>
          </div>

          <MDBFile
            onChange={onImageChange}
            label="Choose an avatar"
            size="lg"
            id="avatar"
            type="file"
          />

          <div className="field button-field">
            <button onClick={register}> {loading ? <LoadingSpinner /> : 'Sign Up'} </button>
            <span className="d-flex justify-content-center align-items-center mt-2">
              Already have an account? &nbsp;{" "}
              <span
                className="formstype"
                onClick={() => props.setFormtype("login")}
              >
                {" "}
                Sign In
              </span>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
}
