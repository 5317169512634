import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";


export default function Forgetpassword(props) {
//  const { addToast } = useToasts();
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  const resetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    let res = await fetch(props.url + "/v1/reset-email-request", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
      credentials: "include",
    });
    let data = await res.json();

    if (await data.success) {
      setLoading(false);
      setError("");
      toast.success("Password reset link sent to " + email);
    } else {
      setLoading(false);
      if (data.message == "invalid") {
        setError("Invalid Client Email Address");
      } else {
        toast.error(data.message);
      }
    }
  };
  return (
    <div className="form-content">
      <header>
        {" "}
        <img
          src="/assets/images/fatt_fatt_normal.png"
          height="50"
          alt=""
          loading="lazy"
        />
      </header>
      <form className="logform">
        <div className="field-column">
          <div className="field input-field">
            <input
              id="email"
              style={{ width: "100%" }}
              type="email"
              placeholder="Email"
              className="input"
              value={email}
              onChange={handleInputChange}
            />
          </div>

          <div className="field button-field">
            <button onClick={resetPassword}>Send Reset Link</button>
            <span className="d-flex justify-content-center align-items-center mt-2">
              Remembered password? &nbsp;{" "}
              <span
                className="formstype"
                onClick={() => props.setFormtype("login")}
              >
                {" "}
                Sign In
              </span>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
}
