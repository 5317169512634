import React, { useEffect, useState } from "react";
import "./About.css";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCardFooter,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBModalBody,
  MDBTabsContent,
  MDBInput,
  MDBFile,
  MDBTextArea,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import convertHtmlToReact from "@hedgedoc/html-to-react";

export default function About(props) {
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const [modalObj, setModalObj] = useState({});
  const [preview, setPreview] = useState("");

  const [section, setSection] = useState({});

  const toggleShow = function (id) {
    if (typeof id != "object" && id != undefined) {
      let obj = section.find((e) => e.id == id);
      setModalObj(obj);
      setPreview(props.imgurl + obj.image);
      console.log("clicked for modal: ", obj);
    }
    setFullscreenXlModal(true);
  };

  const toggleClose = function () {
    setFullscreenXlModal(false);
  };

  const onImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      ///validation no file selected
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      ///validation invalid file
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setModalObj({ ...modalObj, image: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };

  const handleChange = (event) => {
    setModalObj({ ...modalObj, [event.target.id]: event.target.value });
  };

  function fetchDatasec() {
    fetch(props.url + "/section")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setSection([...data]);
        props.setLoadingAbout(false);
      });
  }

  const updateData = async (event) => {
    //async to update data
    event.preventDefault();
    let response = await fetch(props.url + "/update-section", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(modalObj),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setSection(
        section.map((elem) => (elem.id == modalObj.id ? res.data : elem))
      );
      toggleClose();
    }
  };
  useEffect(() => {
    fetchDatasec();
  }, []);

  return (
    <>
      {!props.loadingAbout &&
        !props.loadingGallery &&
        !props.loadingServices &&
        !props.timing && (
          <div className="d-flex" id="about">
            <MDBCard className="w-75">
            {section.map((section, ind) => (
                <MDBRow className=" ">
                  <MDBCol md="4" sm="12" lg="4" xl="4" key={section.id}>
                    <MDBCardImage
                      className="about-img"
                      src={props.imgurl + section.image}
                      alt="img"
                      fluid
                    />
                  </MDBCol>
                  <MDBCol md="8" sm="12" lg="8" xl="8">
                    <MDBCardBody>
                      <MDBCardTitle>
                        <h2>{section.title}</h2>
                        <h6>{section.subtitle}</h6>
                      </MDBCardTitle>
                      <MDBCardText>
                        {/* <span>{  convertHtmlToReact (section.content [true]) }</span> */}
                        <div
                          dangerouslySetInnerHTML={{ __html: section.content }}
                        />
                      </MDBCardText>
                      {props.isAuthenticated && (
                        <MDBIcon
                          className="float-right"
                          onClick={() => toggleShow(section.id)}
                          icon="edit"
                          size="lg"
                        />
                      )}
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              ))}
            </MDBCard>
          </div>
        )}
        
      <MDBModal
        tabIndex="-1"
        show={fullscreenXlModal}
        setShow={setFullscreenXlModal}
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Content Update</MDBModalTitle>
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBTabsContent>
                <MDBRow>
                  {/* Modal for form to update content  */}
                  <MDBCol size="8" sm="6" className="col-example">
                    <MDBInput
                      label=" Enter Title here"
                      id="title"
                      type="text"
                      value={modalObj.title}
                      onChange={handleChange}
                    />
                    <br />
                    <MDBInput
                      label="Enter Content here"
                      id="subtitle"
                      textarea
                      rows={4}
                      value={modalObj.subtitle}
                      onChange={handleChange}
                    />
                    <br />
                    <MDBTextArea
                      label="Message"
                      id="content"
                      rows={4}
                      value={modalObj.content}
                      onChange={handleChange}
                    />
                    <MDBFile
                      onChange={onImageChange}
                      label="Click the icon Below to upload your image"
                      id="customFile"
                    />
                    <br></br>
                    <MDBBtn type="button" color="primary" onClick={updateData}>
                      Update
                    </MDBBtn>
                  </MDBCol>
                  {/* Modal for preveiwing content about to be updated */}
                  <MDBCol
                    className="col-example"
                    size="12"
                    sm="6"
                    key={section.id}
                  >
                    <MDBCard className="w-75">
                      <MDBRow className=" ">
                        <MDBCol xl="4" key={section.id}>
                          <MDBCardImage
                            className="about-img"
                            src={preview}
                            alt="..."
                            fluid
                          />
                        </MDBCol>
                        <MDBCol xl="8">
                          <MDBCardBody>
                            <MDBCardTitle>
                              <h2>{modalObj.title}</h2>
                              <h6>{modalObj.subtitle}</h6>
                            </MDBCardTitle>
                            <MDBCardText>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: modalObj.content,
                                }}
                              />
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBTabsContent>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="button" color="dark" onClick={toggleClose}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
