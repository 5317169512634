import React, { useState } from "react";
import { toast } from "react-toastify";



export default function Resetpassword(props) {
  const [errors, setErrors] = useState([]);
  const [values, setValues] = useState({
    password: "",
    confirm: "",
  });

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const validatePasswords = () => {
    let temp = [];
    temp.password = values.password ? "" : "This field is required";
    if (!values.confirm) {
      temp.confirm = "This field is required";
    } else if (values.confirm !== values.password) {
      temp.confirm = "Please repeat the new password";
    }
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    if (validatePasswords()) {
      let res = await fetch(props.url + "/v1/password-reset", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: props.searchParams.get("email"),
          password: values.password,
          token: props.searchParams.get("token"),
        }),
        credentials: "include",
      });
      let data = await res.json();

      if (await data.success) {
        toast.success("Password changed successfully");
        props.setFormtype("login");
      } else {
        toast.error(data.message);
      }
    }
  };

  return (
    <div className="form-content">
      <div className="headers">
        {" "}
        <img
          src="/assets/images/fatt_fatt_normal.png"
          height="50"
          alt=""
          loading="lazy"
        />
      </div>
      <form className="logform">
        <div className="field-column">
          <div className="field input-field d-flex mb-5 ">
            {" "}
            <input
              id="password"
              type="password"
              placeholder="New Password"
              className="password"
              onChange={handleInputChange}
              value={values.password}
            />
            <i className="bx bx-hide eye-icon"></i>{" "}
          </div>
          <div className="field input-field d-flex mb-5 ">
            <input
              id="confirm"
              type="password"
              placeholder="Confirm Password "
              className="password"
              onChange={handleInputChange}
              value={values.confirm}
            />
            <i className="bx bx-hide eye-icon"></i>{" "}
          </div>
          <div className="field button-field">
            <button onClick={resetPassword}>Reset Password</button>
          </div>
        </div>
      </form>
    </div>
  );
}
