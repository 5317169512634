import React, { useState } from "react";
import "./Contact.css";
import { MDBBtn, MDBIcon, MDBContainer } from "mdb-react-ui-kit";
import { Rating } from "react-simple-star-rating";
import swal from 'sweetalert';



export default function Contact(props) {
  const [inderrors, setinderrors] = useState([]);

  const [feedback, setFeedback] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    message: "",
    rating: 0,
  });

  const validateind = () => {
    let temp = []
    temp.name = feedback.name ? "" : "This field is required"
    temp.message = feedback.message ? "" : "This field is required"
    if (!feedback.email) {
      temp.email = "This field is required"
    } else if (!/\S+@\S+\.\S+/.test(feedback.email)) {
      temp.email = 'Email address is invalid';
    }
    if (!feedback.mobileNumber) {
      temp.mobileNumber = "This field is required"
    } else if (feedback.mobileNumber.length < 7) {
      temp.mobileNumber = 'Phone number should be at least 7 digits';
    }
    setinderrors({ ...temp })
    return Object.values(temp).every(x => x == "")
  }


  // Catch Rating value
  const handleRating = (rate) => {
    setFeedback({ ...feedback, rating: rate });
  };

  const handleChange = (event) => {
    setFeedback({ ...feedback, [event.target.id]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateind()) {
      let res = await fetch(props.url + "/send-feedback", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(feedback),
      });
      const resp = await res.json();
      if (resp.status === 200) {
        setFeedback({
          name: "",
          email: "",
          mobileNumber: "",
          message: "",
          rating: 0,
        });
        swal({
          title: "Message Sent",
          text: "Thanks for your Feedback ",
          icon: "success",
          buttons: false,
          timer:2000,
        })
        
      }
      else{
        setinderrors({ ...inderrors })
      }
    }
  };

  return (
    <MDBContainer className="container contact-us ">
      <div className="contain" id="contact">
        <div className="wrapper">
          <div className="form">
            <h4>GET IN TOUCH</h4>
            <h2 className="form-headline">Send us a message</h2>
            <form id="submit-form" action="" className="cform">
              <p>
                <input
                  id="name"
                  className="form-input"
                  type="text"
                  placeholder="Your Full Name"
                  onChange={handleChange}
                  value={feedback.name}
                />
                <small className="name-error">{inderrors.name}</small>
              </p>
              <p>
                <input
                  id="mobileNumber"
                  className="form-input"
                  type="text"
                  placeholder="Your Phone Number"
                  onChange={handleChange}
                  value={feedback.mobileNumber}
                />
                <small className="name-error">{inderrors.mobileNumber}</small>
              </p>
              <p className="full-width">
                <input
                  id="email"
                  className="form-input"
                  type="text"
                  placeholder="Your Email"
                  required
                  onChange={handleChange}
                  value={feedback.email}
                />
                 <small className="name-error">{inderrors.email}</small>
              </p>

              <p className="full-width">
                <textarea
                  className="textarea"
                  minlength="20"
                  id="message"
                  cols="30"
                  rows="7"
                  onChange={handleChange}
                  placeholder="Your Message"
                  required
                  value={feedback.message}
                ></textarea>
                <small className="name-error">{inderrors.message}</small>
              </p>
              <p className="full-width">
                <Rating
                  onClick={handleRating}
                  ratingValue={feedback.rating}
                  
                ></Rating>{" "}
                &nbsp; &nbsp;
                <MDBBtn onClick={handleSubmit} type="submit" color="dark">
                  Send
                  <MDBIcon far icon="paper-plane" className="ml-5" />
                </MDBBtn>
              </p>
            </form>
          </div>

          <div className="contacts contact-wrapper">
            <ul>
              <li>
              {props.links["contactText"] ? (
                   props.links["contactText"]
                ) : 
                  "Join the team Today Become a corporate partner"
                } 
                
              </li>
              <span className="hightlight-contact-info">
                <li className="email-info">
                  <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                 
                  {props.links["email"] ? (
                   props.links["email"]
                ) : 
                  "info@fattfatt.gm"
                } 
                </li>
                <li>
                  <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                  <span className="">
                  {props.links["phone"] ? (
                   props.links["phone"]
                ) : 
                  "+220 666 3444"
                }</span>
                </li>
              </span>
            </ul>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}
