import React, { useEffect, useState } from "react";
import "./Gallery.css";
import {
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBInput,
  MDBFile,
  MDBModalTitle,
  MDBTabsContent,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export default function Gallery(props) {
  const [preview, setPreview] = useState("");
  const [godalObj, setGodalObj] = useState({});
  const [gallery, setGallery] = useState([]);
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);

  function fetchgallery() {
    fetch(props.url + "/gallerycontent")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const galData = data.data.map((dataone) => {
          return {
            id: dataone.id,
            title: dataone.title,
            subtitle: dataone.subtitle,
            image: dataone.image,
          };
        });
        setGallery(galData);
        console.log("gallery from server: ", galData);
        props.setLoadingGallery(false);
      });
  }

  const toggleShow = function (id) {
    let obj = gallery.find((e) => e.id == id);
    setGodalObj(obj);
    setPreview(props.imgurl + obj.image);
    console.log("clicked for modal2: ", godalObj);
    setFullscreenXlModal(true);
  };

  const toggleClose = function () {
    setGodalObj({});
    setPreview("");
    setFullscreenXlModal(false);
  };

  const updateGalData = async (event) => {
    //async to update data
    event.preventDefault();
    let response = await fetch(props.url + "/updategallery", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(godalObj),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setGallery(
        gallery.map((elem) => (elem.id == godalObj.id ? res.data : elem))
      );
      toggleClose();
    }
  };

  const onGalImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      ///validation no file selected
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      ///validation invalid file
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setGodalObj({ ...godalObj, image: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };

  const handleGalChange = (event) => {
    setGodalObj({ ...godalObj, [event.target.id]: event.target.value });
  };

  useEffect(() => {
    fetchgallery();
  }, []);
  return (
    <>
      {!props.loadingAbout &&
        !props.loadingGallery &&
        !props.loadingServices &&
        !props.timing && (
          <>
            {/* Modal start here */}
            <MDBModal
              tabIndex="-1"
              show={fullscreenXlModal}
              setShow={setFullscreenXlModal}
            >
              <MDBModalDialog size="fullscreen">
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>Gallery Content Manager</MDBModalTitle>
                    <MDBBtn
                      type="button"
                      className="btn-close"
                      color="none"
                      onClick={toggleClose}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <MDBTabsContent>
                      <MDBRow>
                        <MDBCol size="8" sm="6" className="float-left">
                          <MDBInput
                            label=" Enter Title here"
                            id="title"
                            type="text"
                            value={godalObj.title}
                            onChange={handleGalChange}
                          />
                          <br></br>
                          <MDBInput
                            label=" Enter Sub Title here"
                            id="subtitle"
                            type="text"
                            value={godalObj.subtitle}
                            onChange={handleGalChange}
                          />
                          <br></br>
                          <MDBFile
                            onChange={onGalImageChange}
                            label="Click the icon Below to upload your image"
                            id="customFile"
                          />
                          <br></br>
                          <MDBBtn
                            type="button"
                            color="primary"
                            onClick={updateGalData}
                          >
                            Update
                          </MDBBtn>
                        </MDBCol>
                        {/* image preview starts here */}
                        <MDBCol
                          className="float-right"
                          size="8"
                          sm="6"
                          key={gallery.id}
                        >
                          <div
                            className="pl-5 bg-image hover-overlay"
                            style={{ maxWidth: "50%" }}
                          >
                            <MDBCardImage
                              src={preview}
                              alt="..."
                              position="top"
                            />
                            <a href="#!">
                              <div
                                className="pl-5 mask overlay"
                                style={{
                                  backgroundColor: "rgba(210, 215, 211, 0.7)",
                                }}
                              >
                                <h5 className="">{godalObj.title}</h5>
                                <span>{godalObj.subtitle}</span>
                              </div>
                            </a>
                          </div>
                        </MDBCol>
                        {/* image preview ends here */}
                      </MDBRow>
                    </MDBTabsContent>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      type="button"
                      color="dark"
                      onClick={toggleClose}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
            {/* Modal end here */}

            {/* gallery starts here */}
            <section
              className="gallery-area gallery-bg pt-115 pb-120"
              id="gallery"
            >
              <div className="container pt-5">
                <div className="row justify-content-center">
                  <div className="col-lg-8 pb-3">
                    <div className="section-title text-center mb-70  ">
                      <div className="d-flex align-items-center justify-content-center"></div>
                      <h6>Delivery Anything</h6>
                      <h2>Exclusive Cargo Gallery</h2>
                    </div>
                  </div>
                </div>
                <MDBRow className="gallery-active mb-3">
                  <MDBCol lg="8" className="col-example">
                    <div className="pb-1">
                      <div
                        className="bg-image hover-overlay"
                        style={{ maxWidth: "100%" }}
                        key={gallery[0] && gallery[0].id}
                      >
                        <MDBCardImage
                          src={gallery[0] && props.imgurl + gallery[0].image}
                          className="img"
                          alt="img"
                          position="top"
                        />
                        <a href="#!">
                          <div
                            className="mask overlay"
                            style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}
                          >
                            <h5 className="Ghover">
                              {gallery[0] && gallery[0].title} AAA
                            </h5>
                            <span className="Ghover">
                              {gallery[0] && gallery[0].subtitle}
                            </span>
                            {props.isAuthenticated && (
                              <div
                                style={{
                                  padding: "0px 10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "60%",
                                  color: "#F5F5F5",
                                }}
                              >
                                <MDBIcon
                                  onClick={() => toggleShow(gallery[0].id)}
                                  icon="edit"
                                  size="2x"
                                />
                              </div>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </MDBCol>{" "}
                  <MDBCol lg="4" className="col-example">
                    <div className="pb-1">
                      <div
                        className="bg-image hover-overlay"
                        style={{ maxWidth: "100%" }}
                        key={gallery[3] && gallery[3].id}
                      >
                        <MDBCardImage
                          src={gallery[3] && props.imgurl + gallery[3].image}
                          className="img"
                          alt="img"
                          position="top"
                        />
                        <a href="#!">
                          <div
                            className="mask overlay"
                            style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}
                          >
                            <h5 className="Ghover">
                              {gallery[3] && gallery[3].title} bbb
                            </h5>
                            <span className="Ghover">
                              {gallery[3] && gallery[3].subtitle}
                            </span>
                            {props.isAuthenticated && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "70%",
                                  color: "#F5F5F5",
                                }}
                              >
                                <MDBIcon
                                  onClick={() => toggleShow(gallery[3].id)}
                                  icon="edit"
                                  size="2x"
                                />
                              </div>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm="4" className="col-example">
                    <div
                      className="bg-image hover-overlay"
                      style={{ maxWidth: "100%" }}
                      key={gallery[1] && gallery[1].id}
                    >
                      <MDBCardImage
                        src={gallery[1] && props.imgurl + gallery[1].image}
                        className="img"
                        alt="img"
                        position="top"
                      />
                      <a href="#!">
                        <div
                          className="mask overlay ml-5 "
                          style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}
                        >
                          <h5 className="Ghover">
                            {gallery[1] && gallery[1].title} ccc
                          </h5>
                          <span className="Ghover">
                            {gallery[1] && gallery[1].subtitle}
                          </span>
                          {props.isAuthenticated && (
                            <div
                              style={{
                                padding: "0px 10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "60%",
                                color: "#F5F5F5",
                              }}
                            >
                              <MDBIcon
                                onClick={() => toggleShow(gallery[1].id)}
                                icon="edit"
                                size="2x"
                              />
                            </div>
                          )}
                        </div>
                      </a>
                    </div>
                  </MDBCol>
                  <MDBCol sm="4" className="col-example">
                    <div className="pb-1">
                      <div
                        className="bg-image hover-overlay"
                        style={{ maxWidth: "100%" }}
                        key={gallery[2] && gallery[2].id}
                      >
                        <MDBCardImage
                          src={gallery[2] && props.imgurl + gallery[2].image}
                          className="img"
                          alt="img"
                          position="top"
                        />
                        <a href="#!">
                          <div
                            className="mask overlay"
                            style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}
                          >
                            <h5 className="Ghover">
                              {gallery[2] && gallery[2].title} ddd
                            </h5>
                            <span className="Ghover">
                              {gallery[2] && gallery[2].subtitle}
                            </span>
                            {props.isAuthenticated && (
                              <div
                                style={{
                                  padding: "0px 10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "60%",
                                  color: "#F5F5F5",
                                }}
                              >
                                <MDBIcon
                                  onClick={() => toggleShow(gallery[2].id)}
                                  icon="edit"
                                  size="2x"
                                />
                              </div>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol sm="4" className="col-example">
                    <div className="pb-5">
                      <div
                        className="bg-image hover-overlay"
                        style={{ maxWidth: "100%" }}
                        key={gallery[3] && gallery[3].id}
                      >
                        <MDBCardImage
                          src={gallery[4] && props.imgurl + gallery[4].image}
                          className="img"
                          alt="img"
                          position="top"
                        />
                        <a href="#!">
                          <div
                            className="mask overlay"
                            style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}
                          >
                            <h5 className="Ghover">
                              {gallery[4] && gallery[4].title} eee
                            </h5>
                            <span className="Ghover">
                              {gallery[4] && gallery[4].subtitle}
                            </span>
                            {props.isAuthenticated && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "70%",
                                  color: "#F5F5F5",
                                }}
                              >
                                <MDBIcon
                                  onClick={() => toggleShow(gallery[4].id)}
                                  icon="edit"
                                  size="2x"
                                />
                              </div>
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </section>
            {/* gallery ends here */}
          </>
        )}
    </>
  );
}
