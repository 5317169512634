import React, { useState, useEffect } from 'react';
import './App.css';
import { useSearchParams } from 'react-router-dom'
import Nav from './shared/Nav';
// import Header from './shared/Header';
import Services from './component/Service';
import Footer from './shared/Footer';
import Gallery from './component/Gallery';
import Slider from './component/Slide';
import Contact from './component/Contact'
//import Trackorder from './component/Trackorders'
import Trackform from './component/Trackform'
import About from './component/About'
import PreLoader from './component/PreLoader'


export default function App(props) {
  let prod_server = false;

  let imgurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_IMAGE_URL
    : 
    prod_server ?
    process.env.REACT_APP_PRODUCTION_IMAGE_URL_CLOUD :
    process.env.REACT_APP_PRODUCTION_IMAGE_URL_CAYOR;

let url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_URL
    : 
    prod_server ?
    process.env.REACT_APP_PRODUCTION_URL_CLOUD :
    process.env.REACT_APP_PRODUCTION_URL_CAYOR;

let user =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT_URL_EXIT
      : 
      prod_server ?
      process.env.REACT_APP_PRODUCTION_URL_CLOUD_USER_LOGIN :
      process.env.REACT_APP_PRODUCTION_URL_CAYOR_USER_LOGIN;

      
let admin =
process.env.NODE_ENV === "development"
  ? process.env.REACT_APP_DEVELOPMENT_URL_EXIT
  : 
  prod_server ?
  process.env.REACT_APP_PRODUCTION_URL_CLOUD_ADMIN_LOGIN :
  process.env.REACT_APP_PRODUCTION_URL_CAYOR_ADMIN_LOGIN;


  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [isloggedin, setIsloggedin] = useState(false);
  const [timing, setTiming] = useState(true);
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingGallery, setLoadingGallery] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();


  
  const isUserAuthenticated = async () => {

    let response = await fetch(url + '/v1/user', {
      credentials: 'include'
    });
    let data = await response.json();
      console.log('Response',data)
    if (data.success ) {
      if(data.roles[0] === 'content_manager'){
      setisAuthenticated(true);
      setIsloggedin(true);
      }
      else if (data.roles[0] === "admin") {
        setIsloggedin(true);
        window.location.replace(admin);
        return;
      }
      else if (data.roles[0] === "individual" || data.roles[0] === "corporation") {
        setIsloggedin(true);
        window.location.replace(user);
        return;
      }
    }
  }

  const [links, setLinks] = useState({
    footer: "",
    contactText: "",
    facebook: "",
    twitter: "",
    instagram: "",
    phone: "",
    email: "",
  });

  const getName = (arr, name) => {
    return arr.filter((elem) => elem.name.toLowerCase() == name.toLowerCase())
      ? arr.filter((elem) => elem.name.toLowerCase() == name.toLowerCase())[0][
          "value"
        ]
      : "";
  };

  const getLinks = async () => {
    let response = await fetch(url + "/front-get-links", {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setLinks({
        footer: getName(data.data, "Footer Text"),
        contactText: getName(data.data, "Contact Text"),
        facebook: getName(data.data, "Facebook URL"),
        twitter: getName(data.data, "Twitter URL"),
        instagram: getName(data.data, "Instagram URL"),
        phone: getName(data.data, "Primary Phone Number"),
        email: getName(data.data, "Primary Email Address"),
      });
    } else {
      if (data.message == "unauthenticated") {
        props.history.push("/auth");
        return;
      }
    }
  };


  
 useEffect(() => {
  isUserAuthenticated();
  getLinks();
  setTimeout(() => {
    setTiming(false)
  }, 5000);
}, []);

  return (
    <div className='main-page'>
      {(loadingAbout || loadingGallery || loadingServices || timing) &&
      <div className='preloader'>
      <PreLoader />
      </div>
     }
     {(!loadingAbout && !loadingGallery && !loadingServices && !timing)  &&
     <>
      <Nav url={url} user={user} admin={admin} searchParams={searchParams} isloggedin={isloggedin} setIsloggedin={setIsloggedin} isAuthenticated={isAuthenticated}  setisAuthenticated={setisAuthenticated}  />
   <Slider />    
   <Trackform imgurl={imgurl} url={url}/>
   </>
     }
    <About setLoadingAbout={setLoadingAbout} timing={timing} loadingAbout={loadingAbout} loadingGallery={loadingGallery} loadingServices={loadingServices} imgurl={imgurl} url={url}  isAuthenticated={isAuthenticated} />
    <Services setLoadingServices={setLoadingServices} timing={timing} loadingAbout={loadingAbout} loadingGallery={loadingGallery} loadingServices={loadingServices} imgurl={imgurl} url={url} isAuthenticated={isAuthenticated} />
    <Gallery setLoadingGallery={setLoadingGallery} timing={timing} loadingAbout={loadingAbout} loadingGallery={loadingGallery} loadingServices={loadingServices} imgurl={imgurl} url={url} isAuthenticated={isAuthenticated}  />
    {(!loadingAbout && !loadingGallery && !loadingServices && !timing)  &&
    <>
    <Contact links={links} loadingAbout={loadingAbout} loadingGallery={loadingGallery} loadingServices={loadingServices}  url={url} isAuthenticated={isAuthenticated} />
    <Footer links={links} url={url} loadingAbout={loadingAbout} loadingGallery={loadingGallery} loadingServices={loadingServices} />
    </>
}
   
    </div>
  );
}