import React from "react";
import "./footer.css";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { Link } from "react-scroll";

export default function Footer(props) {
 

  return (
    <footer className="new_footer_area bg_color">
      <div className="new_footer_top">
        <div className="container">
          <div className="row small">
            <div className="col-lg-3 col-md-3 ">
              <div
                className="f_widget company_widget wow fadeInLeft"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInLeft",
                }}
              >
                <h3 className="f-title f_600 t_color f_size_18">
                  <img
                    class=""
                    src="/assets/images/fatt_fatt_normal.png"
                    height="30"
                    alt=""
                    loading="lazy"
                  ></img>
                </h3>
                {props.links["footer"] ? (
                  <p> {props.links["footer"]}</p>
                ) : (
                  <p>
                    Fattfatt courier platform was designed to scale and leverage
                    the existing logistics of transporting packages from point A
                    to B.
                  </p>
                )}
                <form
                  action="#"
                  className="f_subscribe_two mailchimp"
                  method="post"
                  novalidate="true"
                  _lpchecked="1"
                >
                  <p
                    className="mchimp-errmessage"
                    style={{ display: "none" }}
                  ></p>
                  <p
                    className="mchimp-sucmessage"
                    style={{ display: "none" }}
                  ></p>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footersmallscreen ">
              <div
                className="f_widget about-widget pl_70 wow fadeInLeft"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInLeft",
                }}
              >
                <h3 className="f-title f_600 t_color f_size_18">Section</h3>
                <ul className="list-unstyled f_list">
                  <li>
                    <Link
                      className="link"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={20}
                      duration={500}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      to="Services"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      to="gallery"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 footersmallscreen nodisplay">
              <div
                className="f_widget about-widget pl_70 wow fadeInLeft"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInLeft",
                }}
              >
                <h3 className="f-title f_600 t_color f_size_18">Services</h3>
                <ul className="list-unstyled f_list">
                  <li>
                    <Link
                      className="link"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={20}
                      duration={500}
                    >
                      Courier Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={20}
                      duration={500}
                    >
                      Errand Request
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={20}
                      duration={500}
                    >
                      Moving Service
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footersmallscreen leftdisplay">
              <div
                className="f_widget social-widget pl_70 wow fadeInLeft"
                data-wow-delay="0.8s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInLeft",
                }}
              >
                <h3 className="f-title f_600 t_color f_size_18">Social Link</h3>
                <div className="f_social_icon">
                  <a
                    href={props.links['facebook'] ? props.links['facebook'] : "https://www.facebook.com/fattfattdeliver/services"}
                    target="_blank"
                    className="fab fa-facebook link"
                  ></a>
                  <a
                    href={props.links['twitter'] ? props.links['twitter'] : "https://twitter.com/fattfattdeliver"}
                    target="_blank"
                    className="fab fa-twitter link"
                  ></a>
                  <a
                    href={props.links['instagram'] ? props.links['instagram'] : "https://www.instagram.com/fattfattdelivery/?hl=en"}
                    target="_blank"
                    className="fab fa-instagram link"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bg">
          <div className="footer_bg_one"></div>
          <div className="footer_bg_two"></div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="footer_content">
          <p>© Fattfatt Inc.. 2022 All rights reserved. Cayor Enterprises</p>
        </div>
      </div>
    </footer>
  );
}
